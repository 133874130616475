import React, { useEffect } from "react";
import { useRouter } from "next/router";

const Home = () => {
  const router = useRouter();

  // 로그인 체크해서 url 이동
  useEffect(() => {
    if (router && router.asPath === "/") {
      router.push("/user/dataAnalysis");
    } else {
      router.push(router.asPath);
    }
  }, []);

  return <p>Loading...</p>;
};

export default Home;
